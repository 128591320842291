/* src/styles/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./colors.css";
@import "./fonts.css";
@import "./reset.css";
@import "./buttons.css";
@import "./forms.css";
@import "./flipImg.css";
@import "./gradients.css";
@import "./headers.css";
@import "./imageMasonry.css";
@import "./menu.css";
@import "./paragraph.css";
@import "./swiper.css";
@import "./sliderImgGallery.css";
@import "./ticker.css";
@import "./forms.css";
@import "./rotatationAnime.css";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-smooth: always;
}
