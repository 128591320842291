/* src/styles/global/menu.css */
/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
  /* margin-top: 15px; */
  z-index: 100;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  margin-top: -4px;
  margin-left: -1px;
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

@keyframes rotateY {
  to {
    transform: rotateY(360deg);
  }
}

.rotating {
  display: inline-block;
  transform-origin: center;
  animation: rotateY 2s forwards;
}

.material-icons.os-preference {
  @apply text-[21px];
}
