/* src/styles/global/swiper.css */
.gallery-swiper-img-wrapper {
  @apply relative mb-7 w-[224px] mx-auto h-[224px];
}

.gallery-swiper-img {
  @apply absolute top-0 left-0 object-cover w-full h-full border-4 rounded-full drop-shadow-xl border-amber-600 dark:border-gray-300;
}

.swiper-slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.touch-manipulation {
  touch-action: manipulation;
}

.swiper-pagination-bullet {
  @apply dark:bg-white bg-gray-600  opacity-50;
}

.swiper-pagination-bullet-active {
  @apply dark:bg-gray-300  bg-black opacity-100;
}
