/* src/styles/global/paragraph.css */
.paragraph-font {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.paragraph {
  @apply leading-relaxed text-[25px] font-medium;
}
