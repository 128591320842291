/* src/styles/global/forms.css */

.glass-background {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.gradient-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.8)
  );
  z-index: 0;
}

body.dark .gradient-overlay::before {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.55),
    rgba(0, 0, 0, 0.8)
  );
}

.boujee-text {
  --bg-size: 400%;
  --color-one: #57ebde;
  --color-two: #aefb2a;
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

.border-anime {
  border-image: linear-gradient(to right, #e600ff 0%, #2fff00 100%) 1;
  border-width: 2px;
  border-style: solid;
  animation: animateGradient 6s infinite linear;
}

@keyframes animateGradient {
  0% {
    border-image: linear-gradient(to right, #e600ff 0%, #2fff00 100%) 1;
  }
  50% {
    border-image: linear-gradient(to right, #2fff00 0%, #e600ff 100%) 1;
  }
  100% {
    border-image: linear-gradient(to right, #e600ff 0%, #2fff00 100%) 1;
  }
}

/* 3 colors boujee */
.boujee-text-three-colors {
  --bg-size: 400%;
  --color-one: #c6ffdd; /* Vibrant orange */
  --color-two: #fbd786; /* Bright yellow */
  --color-three: #f7797d; /* Lime green */
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-three),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .boujee-text-three-colors {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

.gradient-purple {
  background: rgb(209, 120, 255);
  background: radial-gradient(
    circle,
    rgb(209, 120, 255) 0%,
    rgba(182, 50, 252, 1) 100%
  );
}

.gradient-anime-aqua-marine {
  background: linear-gradient(-90deg, #1488cc, #2b32b2);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.gradient-anime-sea-blue {
  background: linear-gradient(45deg, #4568dc, #b06ab3);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.gradient-error {
  background: linear-gradient(90deg, #ff416c 0%, #ff4b2b 100%);
}

.gradient-success {
  background: linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
}
.gradient-green {
  background: linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
}

.gradient-silver {
  background: linear-gradient(90deg, #2c3e50 0%, #bdc3c7 100%);
}

.gradient-twitter-x {
  background: linear-gradient(90deg, #2c3e50 0%, #bdc3c7 100%);
}

.gradient-youtube {
  background: linear-gradient(90deg, #ff0000 0%, #ff5e00 100%);
}

.gradient-gold {
  background: linear-gradient(90deg, #ff5f6d 0%, #ffc371 100%);
}

.gradient-platinum {
  background: linear-gradient(90deg, #16222a 0%, #3a6073 100%);
}

.gradient-anime-weed-bg {
  background: linear-gradient(90deg, #1fa2ff, #12d8fa, #a6ffcb);
  background-size: 300% 300%;
  animation: gradient 8s ease infinite;
}

.gradient-anime-weed-shop {
  background: linear-gradient(180deg, #ef32d9, #89fffd);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.gradient-anime-alert {
  background: linear-gradient(45deg, #f37335, #fdc830);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.gradient-anime-sunshine {
  background: linear-gradient(-45deg, #ffffff, #fdc830);
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.gradient-anime-instagram {
  background: linear-gradient(90deg, #fac20d, #fb1669, #c51592, #6234cb);
  /* background: linear-gradient(-90deg, #ad5389, #3c1053); */
  background-size: 120% 100%;
  /* animation: gradient 8s ease infinite; */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
