/* src/styles/global/headers.css */
h1 {
  @apply text-4xl font-bold;
}

.header-font {
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
