/* src/styles/rotatationAnime.css */
/* rotate animation */
@keyframes rotateAnimation {
  0%,
  100% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
}

@keyframes rotateAnimation-6deg {
  0%,
  100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(10deg);
  }
}

.rotate-animation {
  animation: rotateAnimation 5s infinite;
}

.rotate-animation-7-seconds {
  animation: rotateAnimation-6deg 6s infinite;
  animation-timing-function: ease; /* Adjust the easing function here */
}
