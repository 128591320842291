/* /src/styles/global/fonts.css */
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700");

@font-face {
  font-family: "Helvetica Neue Outline";
  src: url("/src/core/fonts/Helvetica_Neue/HelveticaNeue-Outline.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Font Settings */
.poiret-one {
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.paragraph-font {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.helvetica-neue-outline {
  font-family: "Helvetica Neue Outline", sans-serif;
  font-style: normal;
}
