/* src/styles/global/reset.css */
/* Box-sizing reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margins */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
}

/* Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Remove built-in form typography styles */
input,
button,
textarea,
select {
  font: inherit;
}
