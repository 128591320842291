.form-input {
  @apply block w-full px-3 min-h-[2rem] h-8 py-[1.15rem] border-2 bg-yellow-50 rounded-lg shadow-sm  focus:outline-none text-primary-800 font-medium focus:ring-green-300 focus:border-green-300 tracking-wide;
}

#checkoutForm .form-input {
  @apply text-secondary-600 font-semibold;
}

#checkoutFormBaltimore .form-input-2 {
  @apply block w-full px-3  h-8 py-[1.05rem] border-2 bg-yellow-50  rounded-md border-primary-700  text-primary-800 font-medium tracking-wide;
}

.form-input-contact {
  @apply block w-full px-2 py-2.5 font-medium tracking-wide border-4 rounded-lg shadow-sm bg-secondary-100 border-secondary-200 focus:outline-none text-primary-800 focus:ring-secondary-500 focus:border-secondary-500;
}

.form-input-contact-select {
  @apply block w-full px-2 py-3 min-h-[32px] h-12 font-medium tracking-wide border-4 rounded-lg shadow-sm bg-secondary-100 border-secondary-200 focus:outline-none text-gray-400 focus:ring-secondary-500 focus:border-secondary-500;
}

.form-input::placeholder {
  @apply leading-none text-gray-400;
}

.form-input-login {
  @apply block w-full px-3 min-h-[2rem] h-8 py-5 border-2 bg-yellow-50 rounded-lg shadow-sm  focus:outline-none text-primary-800 font-medium focus:ring-green-300 focus:border-green-300 tracking-wide;
}
